@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
	font-family: AbsolutPro;
	src: url("./assets/fonts/absolut-pro-bold-italic.otf");
}

:root {
	--orange-100: #fcc885;
	--orange-125: #fcbb67;
	--orange-150: #fbad49;
	--orange-175: #fba02b;
	--orange-200: #fa920d;
	--orange-225: #dc800a;
	--orange-250: #bd6e08;
	--orange-275: #9f5b05;
	--orange-300: #804902;
	--orange-200-25: rgb(250, 146, 13, 25%);

	--brown-100: #ff6d28;
	--brown-125: #d4581e;
	--brown-150: #a84414;
	--brown-175: #7d2f0a;
	--brown-200: #511a00;
	--brown-225: #471700;
	--brown-250: #3d1300;
	--brown-275: #321000;
	--brown-300: #280c00;
	--brown-200-25: rgb(40, 12, 0, 25%);

	--amber-100: #fef4e0;
	--amber-125: #fef2d8;
	--amber-150: #fef0d1;
	--amber-175: #feedc9;
	--amber-200: #feebc1;
	--amber-225: #f5d692;
	--amber-250: #edc262;
	--amber-275: #e4ad33;
	--amber-300: #db9803;
	--amber-200-25: rgb(254, 235, 193, 25%);

	--blue-100: #aad5ec;
	--blue-125: #95cbe0;
	--blue-150: #80c1d5;
	--blue-175: #6bb6c9;
	--blue-200: #56acbd;
	--blue-225: #4798ad;
	--blue-250: #38839d;
	--blue-275: #296f8d;
	--blue-300: #1a5a7d;
	--blue-200-25: rgb(86, 172, 189, 25%);
}

::selection {
	background: var(--orange-200);
}

body {
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	background: white;
}

.scrolled {
	/* background-color: rgba(79, 70, 229, 0.05) !important; */
	backdrop-filter: blur(5px);
}

.tool-clip {
	clip-path: polygon(0 75%, 0 0, 100% 25%, 100% 100%);
}

.header-text {
	font-family: AbsolutPro;
}

.gradient-text {
	background: -webkit-linear-gradient(
		150deg,
		var(--orange-150) 25%,
		var(--orange-200) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.gradient-text-secondary {
	background: -webkit-linear-gradient(
		150deg,
		var(--blue-150) 25%,
		var(--blue-200) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.glow-text {
	filter: drop-shadow(0 15px 25px var(--orange-200-25));
}

.glow {
	box-shadow: 0 15px 25px var(--orange-200-25);
}

.glow-text-secondary {
	filter: drop-shadow(0 15px 25px var(--blue-200-25));
}

.glow-secondary {
	box-shadow: 0 15px 25px var(--blue-200-25);
}

canvas {
	position: fixed !important;
	top: 0 !important;
	opacity: 1;
	transition: opacity .3s ease;
}

@media (max-width: 640px) {
	canvas {
		opacity: 0;
	}
}

::-webkit-scrollbar {
	position: absolute;
	opacity: 0;
	width: 10px;
	background: rgba(60 60 60 / .4);
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0 0 0 / .8);
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(50 50 50 / .8);
}